<template>
  <div class="lzlinks-titleConfig-container">
    <el-tabs v-model="activeName" @tab-click="tabClick">
      <!-- 首页标签名设置 -->
      <el-tab-pane label="首页" name="home">
        <el-form
          ref="form"
          :model="indexConfig"
          label-position="left"
          label-width="100px"
          style="width: 35%; margin-left: 30%"
        >
          <el-form-item label="项目运行情况">
            <el-input v-model="indexConfig.title1"></el-input>
          </el-form-item>
          <el-form-item label="告警信息">
            <el-input v-model="indexConfig.title2"></el-input>
          </el-form-item>
          <el-form-item label="项目告警情况">
            <el-input v-model="indexConfig.title3"></el-input>
          </el-form-item>
          <el-form-item label="告警信息统计">
            <el-input v-model="indexConfig.title4"></el-input>
          </el-form-item>
          <el-form-item label="公告">
            <el-input v-model="indexConfig.title5"></el-input>
          </el-form-item>
          <el-form-item label="项目组基站">
            <el-input v-model="indexConfig.title6"></el-input>
          </el-form-item>
          <div style="margin: 0 auto; width: 100px">
            <el-button
              v-if="indexConfigflag"
              @click="putIndexConfig()"
              type="primary"
              >提交修改</el-button
            >
            <el-button v-if="!indexConfigflag" type="info"
              >剩余{{ times }}秒</el-button
            >
          </div>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="告警管理" name="warning">
        <el-form
          ref="form"
          :model="alarmConfig"
          label-position="left"
          label-width="100px"
          style="width: 35%; margin-left: 30%"
        >
          <el-form-item label="设备名称">
            <el-input v-model="alarmConfig.title1"></el-input>
          </el-form-item>
          <el-form-item label="所属项目">
            <el-input v-model="alarmConfig.title2"></el-input>
          </el-form-item>
          <el-form-item label="开始时间">
            <el-input v-model="alarmConfig.title3"></el-input>
          </el-form-item>
          <el-form-item label="结束时间">
            <el-input v-model="alarmConfig.title4"></el-input>
          </el-form-item>
          <el-form-item label="报警时长">
            <el-input v-model="alarmConfig.title5"></el-input>
          </el-form-item>
          <el-form-item label="报警状态">
            <el-input v-model="alarmConfig.title6"></el-input>
          </el-form-item>
          <el-form-item label="报警内容">
            <el-input v-model="alarmConfig.title7"></el-input>
          </el-form-item>
          <el-form-item label="视频抓拍">
            <el-input v-model="alarmConfig.title8"></el-input>
          </el-form-item>
          <el-form-item label="巡检记录">
            <el-input v-model="alarmConfig.title9"></el-input>
          </el-form-item>
          <el-form-item label="操作">
            <el-input v-model="alarmConfig.title10"></el-input>
          </el-form-item>
          <div style="margin: 0 auto; width: 100px">
            <el-button
              v-if="alarmConfigflag"
              @click="putAlarmConfig()"
              type="primary"
              >提交修改</el-button
            >
            <el-button v-if="!alarmConfigflag" type="info"
              >剩余{{ times1 }}秒</el-button
            >
          </div>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="数据大屏" name="LargeData">
        <el-form
          ref="form"
          :model="LargeDataScreenConfig"
          label-position="left"
          label-width="100px"
          style="width: 35%; margin-left: 30%"
        >
          <el-form-item label="设备信息">
            <el-input v-model="LargeDataScreenConfig.title1"></el-input>
          </el-form-item>
          <el-form-item label="组织列表">
            <el-input v-model="LargeDataScreenConfig.title2"></el-input>
          </el-form-item>
          <el-form-item label="历史曲线">
            <el-input v-model="LargeDataScreenConfig.title3"></el-input>
          </el-form-item>
          <el-form-item label="一周告警数">
            <el-input v-model="LargeDataScreenConfig.title4"></el-input>
          </el-form-item>
          <el-form-item label="告警信息">
            <el-input v-model="LargeDataScreenConfig.title5"></el-input>
          </el-form-item>
          <el-form-item label="项目组详情">
            <el-input v-model="LargeDataScreenConfig.title6"></el-input>
          </el-form-item>
          <div style="margin: 0 auto; width: 100px">
            <el-button
              v-if="LargeDataConfigflag"
              @click="putDataScreenConfig()"
              type="primary"
              >提交修改</el-button
            >
            <el-button v-if="!LargeDataConfigflag" type="info"
              >剩余{{ times2 }}秒</el-button
            >
          </div>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  data() {
    return {
      times: 5,
      times1: 5,
      times2: 5,
      setInt: null,
      setInt1: null,
      setInt2: null,
      activeName: "home",
      indexConfig: {},
      alarmConfig: {},
      LargeDataScreenConfig:{},
      indexConfigflag: true,
      alarmConfigflag: true,
      LargeDataConfigflag: true,
    };
  },
  methods: {
    //tabs标签页选中返回
    tabClick(tab){
      if (tab.index==0&&!this.indexConfig.title1) {
      this.getIndexConfig();
      }else if (tab.index==1&&!this.alarmConfig.title1) {
        this.getAlarmConfig();
      }else if (tab.index==2&&!this.LargeDataScreenConfig.title1) {
        this.getDataScreenConfig();
      }
      
    },
    //获取首页配置
    getIndexConfig() {
      this.$api.SetTitleConfig.getIndexConfig().then((res) => {
        this.indexConfig = res.data;
      });
    },
    //获取数据大屏配置
    getDataScreenConfig() {
      this.$api.SetTitleConfig.getDataScreenConfig().then((res) => {
        this.LargeDataScreenConfig = res.data;
      });
    },
     //修改数据大屏配置
    putDataScreenConfig() {
      if (this.LargeDataConfigflag) {
        this.LargeDataConfigflag = false;
        this.$api.SetTitleConfig.putDataScreenConfig(this.LargeDataScreenConfig).then(
          (res) => {
            if (res.code == 200) {
              this.$message({ type: "success", message: res.msg });
              this.getDataScreenConfig();
            }
          }
        );
        this.setInt2 = setInterval(() => {
          this.times2--;
          if (this.times2 == 0) {
            this.times2=5
            clearInterval(this.setInt2);
            this.LargeDataConfigflag = true;
          }
        }, 1000);
      }
    },
    //修改首页配置
    putIndexConfig() {
      if (this.indexConfigflag) {
        this.indexConfigflag = false;
        this.$api.SetTitleConfig.putIndexConfig(this.indexConfig).then(
          (res) => {
            if (res.code == 200) {
              this.$message({ type: "success", message: res.msg });
              this.getIndexConfig();
            }
          }
        );
        this.setInt = setInterval(() => {
          this.times--;
          if (this.times == 0) {
            this.times=5;
            clearInterval(this.setInt)
            this.indexConfigflag = true;
          }
        }, 1000);
      }
    },
    //获取告警配置
    getAlarmConfig() {
      this.$api.SetTitleConfig.getAlarmConfig().then((res) => {
        this.alarmConfig = res.data;
      });
    },
    //修改告警配置
    putAlarmConfig() {
      if (this.alarmConfigflag) {
        this.alarmConfigflag = false;
        this.$api.SetTitleConfig.putAlarmConfig(this.alarmConfig).then(
          (res) => {
            // console.log(res);
            if (res.code == 200) {
              this.getAlarmConfig();
              this.$message({ type: "success", message: res.msg });
            }
          }
        );
        this.setInt1 = setInterval(() => {
          this.times1--;
          if (this.times1 == 0) {
            this.times1=5
            clearInterval(this.setInt1)
            this.alarmConfigflag = true;
          }
        }, 1000);
      }
    },
  },
  created() {
    this.getIndexConfig();
  },
  beforeDestroy() {
    this.times1 = 5;
    this.times2 = 5;
    this.times = 5;
    this.LargeDataConfigflag=true;
    this.indexConfigflag = true;
    this.alarmConfigflag = true;
    clearInterval(this.setInt2)
    clearInterval(this.setInt1)
    clearInterval(this.setInt)
    this.setInt2 = null;
    this.setInt1 = null;
    this.setInt = null;
  },
};
</script>

<style scoped>
@import "../css/lzlinks.css";
</style>